<template>
  <div class="about-us">
    <div id="Content">
      <div class="content-inner">
        <div class="row">
          <div class="col full">
            <img
              src="https://cdn.tickettote.com/media/img/about/studio.png"
              alt="team"
            />
          </div>
        </div>
        <div class="row">
          <div class="col quarterx3 quarter-container">
            <div class="row">
              <div class="col quarterx3 flex center">
                <h1>Our People</h1>
              </div>
            </div>
            <div class="row">
              <div class="col flex center bottom-blue">
                <p>
                  This is our team, a lot of smiling happy people who work hard
                  to empower your teams.
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col quarterx3 flex center">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/0-dGg9EpK10"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div class="row">
              <div class="col flex center incredevent-desc">
                <p>
                  INCREDEVENT is an event management SaaS tool focusing on event
                  ticketing, pre-production and production tools, guest lists
                  and VIP. The platform offers unique services to buyers,
                  planners and promoters with second-to-none customer support
                  rated 5 stars by our clients and their customers.
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col quarterx3 flex center">
                <h2>Who is INCREDEVENT For?</h2>
              </div>
            </div>
            <div class="divider"></div>
            <div class="row divider-text">
              <div class="col quarter">
                <h3 class="buyer">
                  For the Buyer
                </h3>
              </div>
              <div class="col quarterx3">
                Buyers are offered a no nonsense, fair fee buying system with
                continually evolving scalper and price gouging proof tools. Our
                mission is to democrarize event management and crush junk fees.
              </div>
            </div>
            <div class="divider"></div>
            <div class="row divider-text">
              <div class="col quarter">
                <h3 class="planner">
                  For the Planner
                </h3>
              </div>
              <div class="col quarterx3">
                Planners can access dozens of features and essentially run their
                in-person, virtual and hybrid Incredible Events from their
                dashboard, email, ticketing, seating, guest lists and VIP,
                promoter management, and many other features round out our
                unique platform built by event planners for event planners.
              </div>
            </div>
            <div class="divider"></div>
            <div class="row divider-text">
              <div class="col quarter">
                <h3 class="promoter">
                  For the Promoter
                </h3>
              </div>
              <div class="col quarterx3">
                Promoters can finally do away with the often ambiguous
                relationship they have with planners. Cash codes, custom links,
                commission tables, reciprocal payout systems, and promotional
                tools offer promoters, influencers, affiliates, and vendors the
                tracking needed to find success working for planners.
              </div>
            </div>
            <div class="divider"></div>
            <div class="row">
              <div class="col flex center incredevent-desc">
                <p>
                  If you're looking for a new take on an old industry,
                  INCREDEVENT will take your Incredible Events to new heights.
                  As an alternative to TicketMaster, EventBrite and other event
                  management platforms, we strive to create a community for
                  event planners and buyers with tools that matter and constatly
                  evolve.
                </p>
              </div>
            </div>
            <div class="row footer-text">
              <div class="col quarterx3 flex center">
                <p><span>INCREDEVENT</span>; Incredible Events Hosted Here.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.content-inner {
  .full {
    img {
      width: 100%;
    }
  }
  .quarter-container {
    margin: auto;
  }
  h1 {
    font-size: 24px;
    color: white;
  }
  .bottom-blue {
    p {
      margin: 0;
      margin-bottom: 20px;
    }
  }

  iframe {
    border-radius: 10px;
    margin: 24px;
  }

  .incredevent-desc {
    text-align: center;

    p {
      color: var(--text-secondary);
    }
  }
  h3 {
    font-weight: bold;
    margin: 0;
    margin-bottom: 8px;
    font-size: 20px;
    &.buyer {
    }
    &.planner {
      color: var(--primary-green);
    }
    &.promoter {
      color: var(--primary-orange);
    }
  }

  .footer-text {
    p {
      font-size: 20px;
      text-align: center;
      span {
        font-weight: bold;
        background: linear-gradient(
          270deg,
          rgba(68, 182, 120, 1) 0%,
          rgba(43, 172, 217, 1) 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .row.divider-text {
    margin: 16px;
  }

  h2 {
    color: #ffffff;
    text-align: center;
  }

  .individuals-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}
</style>

<script>
import AboutTile from "@/components/AboutTile.vue";

export default {
  name: "about-us",
  components: {
    AboutTile,
  },
  head() {
    return {
      title: { inner: "About Us" },
      meta: [
        {
          name: "description",
          content:
            "Event Management Platform. Sell Tickets. Discover Events. INCREDEVENT is an all-inclusive, industry-leading tool. Who are the creators? Find out here.",
        },
        {
          name: "keywords",
          content: "Events, Tickets, Directory, Promotion",
        },
        {
          name: "author",
          content: "INCREDEVENT",
        },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1.0",
        },
      ],
    };
  },
  data() {
    return {
      staff: [
        {
          title: "Owner",
          name: "Jay Hall",
          description:
            "<p>Jay conceptualizes INCREDEVENT and strategizes direction from the mindset of a marketer and event planner.</p><br><p>Having run over 500 successful events from small club shows to large arena concerts, Jay has made it his mission to give other event planners the perfect ticketing site.</p>",
          image: "Jay.png",
          linkedin: "https://www.linkedin.com/in/jhalldigitalceo/",
        },
        {
          title: "Lead Developer",
          name: "Brett Nelson",
          description:
            "<p>Brett cuts through code with deadly precision and ensures our web dev projects are on track. Coming to Sync from the MITT programming class, Brett was thrown into the role of lead dev with just under a year of experience, and he has definitely met the challenge.</p>",
          image: "Brett.png",
          linkedin: "https://www.linkedin.com/in/brett-nelson-075b1417a/",
        },
        {
          title: "Developer",
          name: "Blake Rumpel",
          description:
            "<p>Michael Jordan + a basketball ... pure genius. Elon Musk + a tech business ... definitive genius. Tony Stark + his suits ... super genius. Blake + code ... well, you get the idea. Blake has literally solved the insolvable 3x in the last year. 🤯</p>",
          image: "Blake.png",
          linkedin: "https://www.linkedin.com/in/blake-rumpel-288a5517a/",
        },
        {
          title: "Developer",
          name: "Dhruv Shah",
          description:
            '<p>"Sandstorm" is a young and hungry member of our dev team who has settled into a front-end specialty. Basically, he makes the sites he works on prettier than even he is. As a central member of our dev team, Dhruv is a driving force behind our tools.</p>',
          image: "Dhruv.png",
          linkedin: "https://www.linkedin.com/in/dhruvshah24/",
        },
      ],
    };
  },
  created() {
    this.$store.commit("setTitle", "About Us");
  },
};
</script>
